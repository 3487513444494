import "react-app-polyfill/stable";
import "core-js";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { icons } from "./assets/icons";

import { Provider } from "react-redux";
import store from "./store";

React.icons = icons;

// TODO: EFR: Filter console warnings.
//const backup = console.warn;
//console.warn = function filterWarnings(msg) {
//    const supressedWarnings = ["'entityName' is assigned a value but never used"];
//    if (!supressedWarnings.some(entry => msg.includes(entry))) {
//        backup.apply(console, arguments);
//    }
//};

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
