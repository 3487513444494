import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
//const rootElement = document.getElementById('root');

const version = document.getElementById("root").getAttribute("version");

const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

class App extends Component {
    constructor(props) {
        super(props);
        if (window.location.hostname.toLowerCase() !== "pneuma.bioxcell.com" && window.location.hostname.toLowerCase() !== "pneuma-uat.bioxcell.com" && window.location.hostname.toLowerCase() !== "pneuma-dev.bioxcell.com" && window.location.hostname.toLowerCase() !== "localhost") {
            window.location.href = "https://bioxcell.com";
        }
    }

    // <BrowserRouter as wrapped with <HashRouter> earlier.
    render() {
        return (
            <BrowserRouter basename={baseUrl}>
                <React.Suspense fallback={loading}>
                    <Switch>
                        <Route path="/" name="Home" render={props => <DefaultLayout version={version} {...props} />} />
                    </Switch>
                </React.Suspense>
            </BrowserRouter>
        );
    }
}

export default App;
