export const sygnet = [
    "32 32",
    `
<g transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M243 294 c-3 -9 -2 -24 4 -33 12 -19 55 -21 61 -3 2 7 -5 12 -17 12
-14 0 -21 6 -21 20 0 24 -18 27 -27 4z"/>
<path d="M30 280 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0 -4
-4 -4 -10z"/>
<path d="M66 253 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z"/>
<path d="M190 231 c-14 -28 13 -55 41 -41 26 14 24 29 -3 22 -18 -4 -20 -2
-16 16 7 27 -8 29 -22 3z"/>
<path d="M102 203 c5 -25 28 -28 28 -4 0 12 -6 21 -16 21 -9 0 -14 -7 -12 -17z"/>
<path d="M147 183 c-4 -3 -7 -15 -7 -25 0 -21 35 -25 46 -5 4 6 4 9 -1 5 -10
-10 -37 11 -29 23 6 11 0 12 -9 2z"/>
<path d="M190 125 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
<path d="M95 120 c-9 -14 3 -30 23 -30 15 1 15 2 -1 11 -9 6 -15 14 -12 20 4
5 4 9 1 9 -3 0 -8 -4 -11 -10z"/>
<path d="M230 90 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M40 61 c0 -10 19 -25 25 -20 1 2 -3 10 -11 17 -8 7 -14 8 -14 3z"/>
</g>
`,
];
