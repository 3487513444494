export const logo = [
	"370.5 177.3",
	`
<style type="text/css">
	.st0{fill:#373435;}
	.st1{fill:url(#SVGID_1_);}
	.st2{fill:url(#SVGID_2_);}
	.st3{fill:url(#SVGID_3_);}
	.st4{fill:url(#SVGID_4_);}
	.st5{fill:url(#SVGID_5_);}
	.st6{fill:url(#SVGID_6_);}
</style>
<title>logo</title>
<g>
	<path class="st0" d="M0,112.6V59.4h35.3c6.5,0.1,10.5,0.2,12.1,0.3c5,0.4,8.5,2.4,10.5,5.9c1.1,2.3,1.6,4.8,1.5,7.4
		c0,3.5-0.5,6-1.4,7.7c-1.2,1.9-3,3.2-5.2,3.9c3.2,0.5,5.4,1.8,6.7,3.7s2,5,2,9.2c0,5.3-1,9-2.9,11c-1.2,1.2-2.7,2.1-4.3,2.6
		c-2.5,0.7-5.1,1.1-7.8,1.2c-2.9,0.2-6.7,0.3-11.2,0.3L0,112.6z M7.1,82.2h28.2c4.7,0,8-0.1,9.7-0.2c1.4-0.1,2.8-0.4,4.1-1.1
		c2-1.1,3-3.5,3-7c0-3.3-0.7-5.5-2.1-6.6c-1-0.7-2.1-1.2-3.3-1.3c-1.3-0.2-5.1-0.3-11.4-0.4H7.1V82.2z M7.1,106.4h28.2
		c3.9,0.1,7.9-0.1,11.8-0.4c1.8-0.1,3.5-0.7,5-1.7c1.4-1.1,2.1-3.4,2.1-6.8c0-3.6-0.8-6-2.3-7.1c-1.1-0.8-2.4-1.3-3.7-1.4
		c-1.4-0.2-5.7-0.3-12.9-0.4H7.1V106.4z"/>
	<path class="st0" d="M67.7,59.4h5.9v6.9h-5.9V59.4z M67.7,75.6h5.9v37h-5.9V75.6z"/>
	<path class="st0" d="M102.2,75.1c4.3-0.1,8.6,0.1,12.9,0.5c2.3,0.2,4.5,0.9,6.5,1.9c2.3,1.4,4.1,3.5,5.1,6c1.2,3.4,1.7,7,1.6,10.6
		c0,4.9-0.6,8.7-1.9,11.3c-0.8,1.8-2.1,3.4-3.7,4.6c-1.8,1.2-3.9,2-6,2.4c-3.9,0.6-7.9,0.9-11.9,0.7c-4,0.1-7.9-0.1-11.9-0.5
		c-2.3-0.2-4.5-0.9-6.5-1.9c-2.4-1.3-4.3-3.3-5.3-5.9c-1.1-2.6-1.6-6.2-1.6-10.9c0-5.2,0.8-9.1,2.3-11.8c1.4-2.6,3.7-4.6,6.5-5.5
		C91,75.6,95.7,75.1,102.2,75.1z M103.2,80.3c-2.9-0.1-5.9,0.1-8.8,0.4c-1.7,0.2-3.3,0.7-4.7,1.6c-1.5,1-2.5,2.5-3,4.2
		c-0.7,2.5-1,5.2-0.9,7.8c0,3.7,0.4,6.6,1.2,8.4c0.8,1.9,2.4,3.3,4.3,4c2.2,0.9,6.6,1.3,13.1,1.3c3.1,0.1,6.2-0.1,9.3-0.5
		c1.7-0.2,3.3-0.8,4.7-1.8c1.4-1,2.4-2.5,2.8-4.1c0.6-2.5,0.9-5.1,0.8-7.7c0-3.9-0.4-6.8-1.3-8.6c-1-2-2.8-3.5-4.9-4.1
		C113.5,80.6,109.3,80.3,103.2,80.3L103.2,80.3z"/>
	<path class="st0" d="M287.6,94.4h6.9c0,3.3-0.3,6.7-0.9,10c-0.9,4-3.5,6.4-7.9,7.3c-3.6,0.7-10,1.1-19.2,1.1
		c-11.9,0-20-0.4-24.1-1.2c-4-0.8-6.7-2.9-8-6.3s-2-9.5-2.1-18.4c0-8.5,0.5-14.7,1.6-18.5c1.2-4.6,4.5-7.2,9.6-8
		c7.3-0.9,14.6-1.3,22-1.1c9.2,0,15.8,0.5,19.8,1.4c1.9,0.4,3.7,1.3,5.1,2.6c1.4,1.2,2.3,2.7,2.8,4.5c0.5,3.1,0.7,6.2,0.7,9.3h-6.6
		c0-0.9,0-1.5,0-1.8c0.1-1.8-0.1-3.7-0.7-5.4c-0.6-1.2-1.6-2.1-2.9-2.5c-2.6-1.1-8.8-1.6-18.4-1.6c-7,0-12.3,0.2-15.8,0.6
		c-3.5,0.4-5.8,1-7,1.9c-1,0.8-1.8,2.5-2.2,5.2c-0.6,3.6-0.8,7.2-0.7,10.8c0,2.2,0.1,5,0.3,8.5c0.2,3.5,0.4,5.8,0.6,7.1
		c0.1,1.7,0.8,3.3,2,4.5c1.4,1.1,3.1,1.7,4.9,1.7c4,0.4,9.8,0.6,17.3,0.6c2.3,0,5.3-0.1,9-0.2c3.7-0.1,6.1-0.3,7.1-0.4
		c1.5-0.1,3-0.5,4.4-1.2c1-0.5,1.7-1.4,2-2.4C287.3,101.2,287.5,98.6,287.6,94.4z"/>
	<path class="st0" d="M340.1,101.2h6.1c-0.1,4.4-1.7,7.5-4.7,9.3s-8.3,2.6-15.8,2.6c-4.7,0.1-9.4-0.2-14.1-0.7
		c-2.6-0.3-5.1-1.1-7.3-2.4c-3.7-2.7-5.6-7.9-5.6-15.7c0-5.5,0.8-9.6,2.4-12.4c1.4-2.6,3.8-4.5,6.5-5.3c2.8-0.9,7.6-1.4,14.3-1.4
		c6.4,0,11,0.3,13.8,1c2.6,0.5,5,1.9,6.8,3.9c1.4,1.6,2.4,3.5,2.9,5.6c0.6,3.2,0.9,6.5,0.9,9.8H305c0.2,4.6,1.1,7.8,2.7,9.5
		c1.5,1.3,3.4,2.2,5.4,2.4c4,0.5,8,0.7,12,0.6c3.1,0.1,6.2-0.1,9.2-0.6c1.6-0.2,3.2-1,4.4-2.2C339.5,104,340,102.6,340.1,101.2z
		 M339.8,90.4c0-3.6-1-6.1-2.9-7.6c-1.6-1.1-3.4-1.8-5.2-2c-3.3-0.4-6.6-0.6-9.9-0.6c-2.9-0.1-5.8,0.1-8.7,0.5
		c-1.7,0.2-3.3,0.9-4.7,1.8c-1.8,1.4-2.9,4-3.2,7.8L339.8,90.4z"/>
	<path class="st0" d="M351.9,59.4h5.9v53.3h-5.9V59.4z"/>
	<path class="st0" d="M364.7,59.4h5.9v53.3h-5.9V59.4z"/>
</g>
<g id="Layer_2_1_">
	<g id="Layer_1-2">

			<radialGradient id="SVGID_1_" cx="245.3053" cy="162.98" r="13.9616" gradientTransform="matrix(1 0 0 -1 0 180)" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style="stop-color:#FFFFFF"/>
			<stop  offset="1" style="stop-color:#0096AB"/>
		</radialGradient>
		<path class="st1" d="M222,7.9c9.2-9.2,24.1-9.2,33.3,0c9.2,9.2,9.2,24.1,0,33.3s-24.1,9.2-33.3,0c0,0,0,0,0,0
			C212.9,32,212.9,17.1,222,7.9z"/>

			<radialGradient id="SVGID_2_" cx="211.0179" cy="128.7124" r="11.9032" gradientTransform="matrix(1 0 0 -1 0 180)" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style="stop-color:#FFFFFF"/>
			<stop  offset="1" style="stop-color:#0096AB"/>
		</radialGradient>
		<path class="st2" d="M191.2,43.5c7.8-7.8,20.6-7.8,28.4,0c7.8,7.8,7.8,20.6,0,28.4c-7.8,7.8-20.6,7.8-28.4,0
			C183.3,64.1,183.3,51.4,191.2,43.5C191.2,43.5,191.2,43.5,191.2,43.5z"/>

			<radialGradient id="SVGID_3_" cx="180.2109" cy="97.7762" r="9.9143" gradientTransform="matrix(1 0 0 -1 0 180)" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style="stop-color:#FFFFFF"/>
			<stop  offset="1" style="stop-color:#0096AB"/>
		</radialGradient>
		<path class="st3" d="M163.7,75.8c6.5-6.5,17.1-6.5,23.7,0c6.5,6.5,6.5,17.1,0,23.7c-6.5,6.5-17.1,6.5-23.7,0c0,0,0,0,0,0
			C157.2,92.9,157.2,82.3,163.7,75.8z"/>

			<radialGradient id="SVGID_4_" cx="150.6469" cy="67.357" r="8.2338" gradientTransform="matrix(1 0 0 -1 0 180)" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style="stop-color:#FFFFFF"/>
			<stop  offset="1" style="stop-color:#0096AB"/>
		</radialGradient>
		<path class="st4" d="M136.9,107.3c5.4-5.4,14.2-5.5,19.7,0s5.5,14.2,0,19.7c-5.4,5.4-14.2,5.5-19.7,0c0,0,0,0,0,0
			C131.5,121.5,131.5,112.7,136.9,107.3z"/>

			<radialGradient id="SVGID_5_" cx="120.4266" cy="37.5543" r="6.3344" gradientTransform="matrix(1 0 0 -1 0 180)" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style="stop-color:#FFFFFF"/>
			<stop  offset="1" style="stop-color:#0096AB"/>
		</radialGradient>
		<path class="st5" d="M109.9,138.3c4.2-4.2,11-4.2,15.1,0c4.2,4.2,4.2,11,0,15.1c-4.2,4.2-11,4.2-15.1,0c0,0,0,0,0,0
			C105.7,149.2,105.7,142.5,109.9,138.3z"/>

			<radialGradient id="SVGID_6_" cx="94.9994" cy="11.9978" r="4.1666" gradientTransform="matrix(1 0 0 -1 0 180)" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style="stop-color:#FFFFFF"/>
			<stop  offset="1" style="stop-color:#0096AB"/>
		</radialGradient>
		<path class="st6" d="M88,165.3c2.7-2.8,7.2-2.8,10,0c2.8,2.7,2.8,7.2,0,10s-7.2,2.8-10,0c0,0,0,0,0,0
			C85.3,172.5,85.3,168,88,165.3z"/>
		<path class="st0" d="M157.6,58.7c4.3,4.3,4.3,11.3,0,15.6c-4.3,4.3-11.3,4.3-15.6,0c-4.3-4.3-4.3-11.3,0-15.6
			C146.3,54.4,153.3,54.4,157.6,58.7C157.6,58.7,157.6,58.7,157.6,58.7z"/>
		<path class="st0" d="M133,37.2c3.3,3.3,3.3,8.7,0,12s-8.7,3.3-12,0c-3.3-3.3-3.3-8.7,0-12c0,0,0,0,0,0
			C124.3,33.9,129.7,33.9,133,37.2z"/>
		<path class="st0" d="M111.6,20c2.2,2.2,2.2,5.7,0,7.9s-5.7,2.2-7.9,0c-2.2-2.2-2.2-5.7,0-7.9C105.9,17.8,109.4,17.8,111.6,20z"/>
		<path class="st0" d="M207.5,100.8c4.3,4.3,4.3,11.3,0,15.6s-11.3,4.3-15.6,0c-4.3-4.3-4.3-11.3,0-15.6
			C196.2,96.5,203.2,96.5,207.5,100.8C207.5,100.8,207.5,100.8,207.5,100.8z"/>
		<circle class="st0" cx="218.8" cy="128.3" r="7.5"/>
		<path class="st0" d="M233.8,150.6c-2.2-2.1-2.4-5.6-0.2-7.9c2.1-2.2,5.6-2.4,7.9-0.2c2.2,2.1,2.4,5.6,0.2,7.9c0,0,0,0,0,0
			C239.6,152.6,236.1,152.7,233.8,150.6C233.8,150.6,233.8,150.6,233.8,150.6z"/>
	</g>
</g>
`,
];
